/*
    Name: common.js
*/

// Document Ready Starts
$(document).ready(function() {


    // #############################################
    //Form Validation :::
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    });


    // #############################################
    //Custom Select Dropdown && Multiselect :::
    //$('.custom-select-dropdown').SumoSelect();

    // reload native select after changing option
    $('.SumoSelect .optWrapper li').click(function(event) {
        event.stopPropagation();
        $('select.custom-select-dropdown')[0].sumo.reload();
    });

    //closing nav menus if click outside
    $('body').on('click', function(event){
        if (event.target != $('li.drop a')[0])
            $('li.drop ul').slideUp(150);
        if (event.target != $('li.first a')[0])
            $('.product-dropdown').slideUp(150);
    });


    // #############################################
    //Lightbox Initiations ::::
    $('.open-popup-link').magnificPopup({
        type:'inline',
        showCloseBtn: true,
        closeOnBgClick: true,
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    // #############################################
    // TABS

    $('.js-tab-triggers .trigger').on('click', function(event) {
        event.preventDefault();
        $('.js-tab-triggers .trigger').not(this).removeClass('active');
        $(this).addClass('active');
        // var getTarget = $(this).attr('href');
        // $('.tab-content').not(getTarget).hide();
        // $(getTarget).addClass('active');
        // $(getTarget).show();
         $('.tab-content').hide();
         $(this).next().show();
    });

    // #############################################
    //PRODUCT DROPDOWN

    $('.js-product-dropdown').on('click', function(event) {
        event.stopPropagation();
        event.preventDefault();
        $('.product-dropdown').slideToggle(150);
        $('li.drop ul').slideUp(150);
    });

    // #############################################
    //SEARCH DROPDOWN

    $('.js-toggle-search').on('click', function(event) {
        event.preventDefault();
        $('.search-dropdown').toggleClass('active');
        $('.search-dropdown').find('#keyword').focus();
    });

    // #############################################
    //SHOW NUMBER

    $('.js-show-number').on('click', function() {
        var number = $(this).data('number');
        var numberFull = $(this).data('number-full');
        $(this).html('<a href="tel:'+ number +'">'+ numberFull +'</a>');
    });

    // #############################################
    //Downloads Dropdown

    $('li.drop').on('click', function(event) {
        event.stopPropagation();
        event.preventDefault();
        $(this).children('ul').slideToggle(200);
        $('.product-dropdown').slideUp(150);
    });
    
    $(".drop ul").on("click", "a", function(event){
        event.stopPropagation();
    })

    // #############################################

    $('input, select, textarea').blur(function(){
        tmpval = $(this).val();
        if(tmpval == '') {
            $(this).parent('.form-field').removeClass('not-empty');
        } else {
            $(this).parent('.form-field').addClass('not-empty');
        }
    });

    // #############################################
    var $document = $(document),
        $element = $('.main-container'),
        $element2 = $('.product-dropdown')

    $document.scroll(function() {
    if ($document.scrollTop() >= 36) {
        $element.addClass('fixed');
        $element2.addClass('fixed');
    } else {
        $element.removeClass('fixed');
        $element2.removeClass('fixed');
    }
    });

    // #############################################
    

});


$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


// #############################################
//Main Menu ::: 
function slideMenu(action){ 
    var $menu = $('#mobile-menu') 
    if (!action) { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-menu');
        $menu.attr('status', 'open');
    }
    function close(){
        $("body").removeClass('open-menu');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

$('#mobile-menu .menu-content > ul > li').each(function(){
    $(this).has("ul").addClass('mobile-menu-parent');
});
$('#mobile-menu span').click(function(e){
    e.stopPropagation();
    $(this).next('ul').slideToggle(150);
    $(this).toggleClass('active');
});

$( ".mobile-menu-parent" ).on("click", function(e){
    e.preventDefault();
    $(this).find('> ul').slideToggle(150);
    $(this).toggleClass('active');
    $(this).parent().toggleClass('active');
});

//prevent slide up
$(".mobile-menu-parent").on("click", "ul", function(event){
    event.stopPropagation()
})

// #############################################
// Header Highlighter :::
// By David - functionality af
$(document).ready(function() {
    var headerHref = "#site-header";
    var mobileHref = "#mobile-menu";
    var myURL = "/" + location.pathname.split('/')[1];
    console.log(myURL)

    $(headerHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })

    $(mobileHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })
})





